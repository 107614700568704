body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.left-bar {
  bottom: 0;
    min-width: 100px;
    position: relative;
    top: 0;
    width: 360px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.center {
  bottom: 0;
  left: 360px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
  padding: 0px 10px;
}

.layers {
  margin: 5px;
}

.timeline {
    margin: 5px;
    overflow: auto;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    /* flex: 1 1 auto; */
}


.ui.search.CISearch>.results .result {
  padding: 0px;
}

.ui.search.CISearch>.results .result a {
  display: inline-block;
  padding: .85714286em 1.14285714em;
  width: 100%;
}

/* HACK to make semantic popups work alongside bootstrap */
.semantic-popup {
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
}

.cell-wrap-text {
  white-space: normal !important;
}

/* HACK workaround to make antd spin behave well with dynamic children and full height components */
.ant-spin-nested-loading {
  display: inherit;
  flex-grow: inherit;
  flex-direction: inherit;
  width: 100%;
}
.ant-spin-container {
  display: inherit;
  flex-grow: inherit;
  flex-direction: inherit;
  width: inherit;
}

.ant-tabs-content-holder {
  display: flex;
}