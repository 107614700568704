/* new entry */
.ag-theme-balham .new {
    background-color: #66c2a5;
}

/* edited entry */
.ag-theme-balham .edited {
    background-color: #c2d4ff;
}

/* clean entry */
.ag-theme-balham .clean {
    background-color: white;
}

/* deleted entry */
.ag-theme-balham .deleted {
    background-color: #fc8d62;
}

/* ant design popover */
.ant-popover {
    z-index: 1050; /* seems like antd's select has a z-index of 1049 -> use 1050 to show antd-popover on top of that '*/
}
