#root {
  height: 100%;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* ag grid popup */
.ag-theme-balham .ag-popup {
    height: auto; /* resize, so you have no white background*/
}

/* HACK: ant design sets box-sizing globally to "border-box" and graphiql does not like this
  so we manually reset the box-sizing inside the graphiql-container and its children */
.ant-layout .graphiql-container * {
  box-sizing: unset;
}


/* modify ant-design's tree classes to fix issues in effective trait tree in search view 
TODO: consider making this more specific to not interfere with other uses of ant-design tree
*/
.ant-tree-node-content-wrapper {
  flex-grow: 1;
  min-width: 0px; /* see https://stackoverflow.com/a/41675912 for an explanation */
}

.workaround-antd-spinner-flex-full-height {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}